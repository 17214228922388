<template lang="html">

<article :id="pos">
    <div :class="{white: 'white' == body.cttext1background,grey: 'grey' == body.cttext1background,tabelleNormal: 'normal' == body.tabelleDesign,tabelleBlock: 'block' == body.tabelleDesign}">
        <vue-markdown :class="{'headline-green': 'white' == body.cttext1background,'headline-grey': 'grey' == body.cttext1background}" class="ct markdown" :source="body.inhalt"></vue-markdown>
    </div>
</article>

</template>

<script>

import VueMarkdown from 'vue-markdown';
import $ from 'jquery';

export default {
    name: 'ContentTypeText1',
    props: {
        body: {
            type: Object
        },
        pos: {
            type: Number
        }
    },
    components: {
        VueMarkdown
    },
    mounted() {
        $('#' + this.pos + " table").wrap("<div class='col table-fcmh'></div>");

        $('#' + this.pos + " .table-fcmh").wrapAll("<div class='row'></div>");
    }
}

</script>

<style lang="css" scoped>



</style>
