<template lang="html">

<section id="headerOhneBild">
    <div class="header-wrapper">
      <div class="text-center header-inner-wrapper">
        <h1 class="headline-white">{{body.inhalt}}</h1>
      </div>
    </div>
</section>

</template>

<script>

export default {
    name: 'ContentTypeHeaderOhneBild',
    data() {
        return {
            url: process.env.VUE_APP_STRAPI_API_URL
        }
    },
    props: {
        body: {
            type: Object
        },
        pos: {
            type: Number
        }
    }
}

</script>

<style lang="css" scoped>

#headerOhneBild {
    height: calc(70rem - 2vw);
}

#headerOhneBild h1 {
    font-size: 7rem;
    background-position: 0 92%;
    line-height: 9rem;
    background-size: 100% 0.3em;
}

.header-inner-wrapper{
  display: block;
}

.header-wrapper {
    height: calc(70rem - 2vw);
    height: -moz-calc(70rem - 4%);
    height: -webkit-calc(70rem - 4%);
    background: var(--fcmh-dark-green-2);
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: 1rem solid var(--fcmh-olive);
}

@media screen and (max-width: 96.875em) {
    .header-wrapper,
    #headerOhneBild {
        height: calc(60rem - 2vw);
    }
}

@media screen and (max-width: 58.125em) {
    #headerOhneBild h1 {
        font-size: 5rem;
    }
    #headerOhneBild h1 {
        line-height: 7rem;
    }
}

@media screen and (max-width: 34.375em) and (max-height: 56.25em) {
    .header-wrapper,
    #headerOhneBild {
        height: calc(50rem - 2vw);
    }
}

@media screen and (max-width: 34.375em) and (max-height: 45.625em) {
    .header-wrapper,
    #headerOhneBild {
        height: calc(40rem - 2vw);
    }
}

@media screen and (max-width: 34.375em) and (max-height: 39.375em) {
    .header-wrapper,
    #headerOhneBild {
        height: calc(30rem - 2vw);
    }
}

@media screen and (max-width: 26.875em) {
    #headerOhneBild h1 {
        font-size: 4rem;
    }
    #headerOhneBild h1 {
        line-height: 5.5rem;
    }
}

@media screen and (max-width: 23.125em) {
    #headerOhneBild h1 {
        font-size: 3rem;
    }
    #headerOhneBild h1 {
        line-height: 4rem;
    }
}

</style>
