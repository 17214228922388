<template>
  <article class="ct markdown">
    <div class="headline-green">
      <h2>{{ body.headline }}</h2>
    </div>
    <p class="ctd-link" v-for="file in body.document" :key="file.id">
      <a :href="url + file.datei.url" target="_blank" download>
        <lord-icon target="a" animation="hover" src="/assets/199-download.json"></lord-icon>
        {{ file.name }}
      </a>
    </p>
  </article>
</template>

<script>
export default {
  name: 'ContentTypeDownload',
  data(){
    return {
      url: process.env.VUE_APP_STRAPI_API_URL
    };
  },
  props: {
    body: {
      type: Object
    },
    pos: {
      type: Number
    }
  }
}
</script>

<style scoped>

.ctd-link{
  display: table;
  font-weight: 600;
  background-color: var(--fcmh-light-grey);
  padding: 1rem 2.5rem;
  border-radius: 5px;
}

.ctd-link>*{
  color: var(--fcmh-dark-grey);
}

.ctd-link>*:hover{
  text-decoration: none;
}

</style>
