<template lang="html">

<section id="ls-1">
    <div :style="{'backgroundImage': 'url(' + url + body.img.url +')'}" class="carousel-wrapper">
        <div class="carousel-inner-wrapper">
            <carousel ref="fcmhCarousel" class="fcmh-carousel" :paginationEnabled="false" :perPageCustom="[[1,1],[500,1],[800, 2],[1200, 3],[1900, 4]]" :scrollPerPage="false">
                <slide class="department" v-for="department in body.DepartmentHighlight" :key="department.id">
                    <div class="inner-slide">
                        {{department.abteilungen.name}}
                        <router-link class="btn-default" :to="{ name: 'Page', params: {slug: department.unterseiten.slug} }">Mehr erfahren <img src="/assets/small-arrow.svg" alt="Pfeil"> </router-link>
                    </div>
                </slide>
            </carousel>
        </div>
    </div>
    <div class="carousel-navigation">
        <button class="fas fa-angle-left" type="button" @click="SlideCarousel('prev')" name="button"></button>
        <button class="fas fa-angle-right" type="button" @click="SlideCarousel('next')" name="button"></button>
    </div>
</section>

</template>

<script>

import {
    Carousel, Slide
}
from 'vue-carousel';

export default {
    name: 'ContentTypeLandingsection1',
    data() {
        return {
            url: process.env.VUE_APP_STRAPI_API_URL
        }
    },
    components: {
        Carousel,
        Slide
    },
    props: {
        body: {
            type: Object
        },
        pos: {
            type: Number
        }
    },
    methods: {
        SlideCarousel(value) {
            if (typeof this.$refs.fcmhCarousel !== 'undefined') {
                const carousel = this.$refs.fcmhCarousel;
                const currentPage = carousel.currentPage;
                const pageCount = carousel.pageCount;
                if (value == 'prev') {
                    currentPage != 0 ? carousel.goToPage(currentPage - 1) : carousel.goToPage(pageCount - 1);
                    this.activePage = this.activePage - 1;
                } else {
                    currentPage < pageCount - 1 ? carousel.goToPage(currentPage + 1) : carousel.goToPage(0);
                    this.activePage = this.activePage + 1;
                }
            }
        }
    }
}

</script>

<style lang="css" scoped>

#ls-1 {
    position: relative;
    /* height: 90vh; */
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    background-position-y: -15rem;
    background-size: 100%;
    background-repeat: no-repeat;
}

#ls-1 .btn-default {
    font-size: 1.7rem;
    padding: 0;
    line-height: inherit;
    bottom: 0;
    position: absolute;
    padding-bottom: 2rem;
}

.inner-slide:hover {
    background-color: var(--fcmh-olive);
}

.inner-slide {
    max-width: 25rem;
    height: 25rem;
    padding: 3vw .5vw;
    background-color: var(--fcmh-dark-green);
    color: white;
    margin: 2rem;
    font-size: 3rem;
    word-break: break-word;
    font-weight: 600;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    text-align: center;
    position: relative;
    box-shadow: 0px 5px 30px rgba(45, 63, 50, 0.5);
}

.carousel-navigation {
    display: flex;
    justify-content: center;
    /* margin: 3vw 0; */
    margin: 10rem 0 5rem 0;
}

.carousel-navigation button {
    background-color: var(--fcmh-green);
    color: white;
    border: none;
    font-size: 4rem;
    margin: 0 1rem;
    padding: 1rem 2rem;
}

.carousel-wrapper {
    /* padding-top: 30%; */
    padding-top: calc(70rem - 4%);
    background-repeat: no-repeat;
    background-size: cover;
    background-position-y: 50%;
    background-position-x: center;
    position: relative;
}

.carousel-inner-wrapper {
    position: absolute;
    top: 60%;
    /* top: calc(50rem + 4%); */
    overflow: visible;
    width: 100%;
}

@media screen and (max-width: 96.875em) {
    .carousel-wrapper {
        padding-top: calc(60rem - 4%);
    }
}

@media screen and (max-width: 93.75em) {
    .inner-slide {
        margin: 0 auto;
    }
}

@media screen and (max-width: 34.375em) and (max-height: 56.25em) {
    .carousel-wrapper {
        padding-top: calc(50rem - 4%);
    }
}

@media screen and (max-width: 34.375em) and (max-height: 45.625em) {
    .carousel-wrapper {
        padding-top: calc(40rem - 4%);
    }
    .inner-slide {
        max-width: 22rem;
        height: 22rem;
        font-size: 2.2rem;
    }
    .carousel-navigation button {
        font-size: 3rem;
    }
}

@media screen and (max-width: 34.375em) and (max-height: 39.375em) {
    .carousel-wrapper {
        padding-top: calc(30rem - 4%);
    }
    .carousel-inner-wrapper {
        top: 45%;
    }
    .inner-slide {
        max-width: 20rem;
        height: 20rem;
        font-size: 2.2rem;
    }
    .carousel-navigation button {
        font-size: 2.5rem;
    }
}

</style>
