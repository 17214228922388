<template lang="html">

<footer id="footer">
    <div id="footerDesktop">
        <div class="row">
            <div v-if="this.footer.logo != null" class="col">
                <div class="logo-wrapper">
                    <img :src="url + this.footer.logo.url" :alt="this.footer.logo.alternativeText">
                </div>
            </div>
            <div v-if="this.footer.footerText.inhalt != null" class="col">
                <div class="footer-text-wrapper">
                    <article>
                        <vue-markdown style="color:white;" class="markdown" :source="this.footer.footerText.inhalt"></vue-markdown>
                        <router-link v-if="this.footer.footerText.link.page.slug != null" :class="{'btn-green': this.footer.footerText.link.style == 'green', 'btn-grey': this.footer.footerText.link.style == 'grey', 'btn-default': this.footer.footerText.link.style == 'default'}"
                        :to="{ name: 'Page', params: {slug: this.footer.footerText.link.page.slug} }">
                            <label>{{this.footer.footerText.link.label}}</label>
                        </router-link>
                    </article>
                </div>
            </div>
            <div v-if="this.footer.copyright" class="col">
                <div class="copyright-wrapper">
                    <p class="copyright">{{this.footer.copyright}}</p>
                </div>
            </div>
            <div class="col">
                <div class="link-wrapper">
                    <div class="link-inner-wrapper">
                        <div class="link" v-for="link in this.footer.link" :key="link.id">
                            <router-link :class="{'btn-green': link.style == 'green', 'btn-grey': link.style == 'grey', 'btn-default': link.style == 'default'}" v-if="link.page.slug != null" :to="{ name: 'Page', params: {slug: link.page.slug} }">
                                <label>{{link.label}}</label>
                            </router-link>
                        </div>
                        <router-link id="datenschutz" class="btn-default" :to="{ name: 'Page', params: {slug: this.footer.datenschutz.page.slug} }">{{this.footer.datenschutz.label}}</router-link>
                        <router-link id="impressum" class="btn-default" :to="{ name: 'Page', params: {slug: this.footer.impressum.page.slug} }">{{this.footer.impressum.label}}</router-link>
                    </div>
                </div>
            </div>
            <div class="col">
                <div class="scroll-to-top-wrapper">
                    <button @click="scrollToTop" type="button" name="button" class="scroll-to-top fas fa-angle-up"></button>
                </div>
            </div>
        </div>
    </div>
    <div id="footerMobile">
        <div class="row">
            <div v-if="this.footer.logo != null" class="col">
                <div class="logo-wrapper">
                    <img :src="url + this.footer.logo.url" :alt="this.footer.logo.alternativeText">
                </div>
            </div>
            <div class="col">
                <div class="scroll-to-top-wrapper">
                    <button @click="scrollToTop" type="button" name="button" class="scroll-to-top fas fa-angle-up"></button>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <div class="link-wrapper">
                    <div class="link-inner-wrapper">
                        <div class="link" v-for="link in this.footer.link" :key="link.id">
                            <router-link :class="{'btn-green': link.style == 'green', 'btn-grey': link.style == 'grey', 'btn-default': link.style == 'default'}" v-if="link.page.slug != null" :to="{ name: 'Page', params: {slug: link.page.slug} }">
                                <label>{{link.label}}</label>
                            </router-link>
                        </div>
                        <router-link id="datenschutz" class="btn-default" :to="{ name: 'Page', params: {slug: this.footer.datenschutz.page.slug} }">{{this.footer.datenschutz.label}}</router-link>
                        <router-link id="impressum" class="btn-default" :to="{ name: 'Page', params: {slug: this.footer.impressum.page.slug} }">{{this.footer.impressum.label}}</router-link>
                    </div>
                </div>
            </div>
            <div v-if="this.footer.footerText.inhalt != null" class="col">
                <div class="footer-text-wrapper">
                    <article>
                        <vue-markdown style="color:white;" class="markdown" :source="this.footer.footerText.inhalt"></vue-markdown>
                        <router-link v-if="this.footer.footerText.link.page.slug != null" :class="{'btn-green': this.footer.footerText.link.style == 'green', 'btn-grey': this.footer.footerText.link.style == 'grey', 'btn-default': this.footer.footerText.link.style == 'default'}"
                        :to="{ name: 'Page', params: {slug: this.footer.footerText.link.page.slug} }">
                            <label>{{this.footer.footerText.link.label}}</label>
                        </router-link>
                    </article>
                </div>
            </div>
        </div>
        <div class="row">
            <div v-if="this.footer.copyright" class="col">
                <div class="copyright-wrapper">
                    <p class="copyright">{{this.footer.copyright}}</p>
                </div>
            </div>
        </div>
    </div>
</footer>

</template>

<script>

import VueMarkdown from 'vue-markdown';
import gql from 'graphql-tag';
export default {
    name: 'Footer',
    data() {
        return {
            footer: {},
            url: process.env.VUE_APP_STRAPI_API_URL
        }
    },
    components: {
        VueMarkdown
    },
    methods: {
        scrollToTop() {
            window.scrollTo(0, 0);
        }
    },
    apollo: {
        footer: {
            query: gql `
      query {
        footer{
          id
          logo{
            id
            url
            alternativeText
          }
          copyright
          link{
            id
            label
            link
            style
            page{
              id
              slug
            }
          }
          datenschutz{
            id
            label
            link
            page{
              id
              slug
            }
          }
          impressum{
            id
            label
            link
            page{
              id
              slug
            }
          }
          footerText{
            id
            inhalt
            link{
              id
              label
              link
              style
              page{
                id
                slug
              }
            }
          }
        }
      }
      `
        }
    }
}

</script>

<style lang="css" scoped>

footer {
    background-color: var(--fcmh-dark-green);
}

footer * {
    font-size: 2.5rem;
}

footer .markdown {
    white-space: normal;
}

#footerMobile {
    display: none;
}

.row {
    margin: 0;
}

.logo-wrapper img {
    width: 5vw;
    padding: 5rem 0;
}

.logo-wrapper {
    max-height: 100%;
    display: block;
    text-align: center;
}

.copyright {
    color: var(--fcmh-olive);
    font-size: 2rem;
    font-family: 'Oswald', sans-serif;
    font-weight: 500;
}

.copyright-wrapper {
    display: flex;
    align-items: flex-end;
    height: 100%;
}

.scroll-to-top {
    background-color: var(--fcmh-green);
    color: white;
    border: none;
    font-size: 4rem;
    margin: 0 1rem;
    padding: 1rem 2rem;
}

.scroll-to-top-wrapper {
    height: 100%;
    padding-top: 7rem;
    display: block;
    justify-content: center;
    align-items: center;
}

.link-wrapper {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    align-items: center;
}

.link-wrapper * {
    font-family: 'Oswald', sans-serif;
    color: white;
    font-weight: 500;
}

.link {
    margin: 1rem 0;
}

.link-inner-wrapper {
    text-align: left;
}

#datenschutz {
    margin-right: 2rem;
}

#datenschutz,
#impressum {
    font-size: 2rem;
}

.footer-text-wrapper {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 2rem 0;
}

@media screen and (max-width: 106.25em) {
    #footerDesktop {
        display: none;
    }
    #footerMobile {
        display: block;
        flex-direction: column;
        padding: 0 2vw;
        text-align: center;
    }

    #footerMobile .col{
      max-height: min-content;
    }

    .link{
      text-align: center;
    }
    
    .footer-text-wrapper,
    .link-wrapper {
        min-width: 35rem;
    }

    .logo-wrapper img {
        width: 10rem;
    }

    .copyright-wrapper {
        justify-content: center;
    }
}

@media screen and (max-width: 65.6875em) {
    #app a.btn-green,
    #app a.btn-grey,
    #app a.btn-default {
        padding: 0;
    }
    .copyright,
    #datenschutz,
    #impressum {
        font-size: 1.7rem;
    }
    footer *,
    #app .markdown > p {
        font-size: 2rem;
    }
}

@media screen and (max-width: 28.125em) {
    .footer-text-wrapper,
    .link-wrapper {
        min-width: 20rem;
        width: 100%;
    }
    .logo-wrapper img {
        width: 8rem;
        padding: 2.5rem 0;
    }
    .scroll-to-top {
        font-size: 3rem;
        padding: 1rem 1.5rem;
    }
    .scroll-to-top-wrapper{
        padding-top: 5rem;
    }
}

</style>
