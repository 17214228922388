<template>
  <article>
      <button v-on:click="_dropdown" class="btn-style">
        <lord-icon v-if="isOpen" target="button" animation="hover" src="/assets/34-arrow-up.json"></lord-icon>
        <lord-icon v-else target="button" animation="hover" src="/assets/33-arrow-down.json"></lord-icon>
        <h2 class="dropdownHeadline dropdown-headline">{{ body.headline }}</h2>
      </button>

      <div class="dropdown-style" v-bind:class="{ close: !isOpen }">
        <vue-markdown class="markdown" :source="body.dropdowntext"></vue-markdown>
      </div>

  </article>
</template>

<script>
import VueMarkdown from 'vue-markdown';

export default {
  name: 'Dropdown',
  props: {
    body: {
      type: Object
    }
  },
  components: {
    VueMarkdown
  },
  data() {
    return{
      isOpen: false
    }
  },
  methods: {
    _dropdown: function(){
      if(!this.isOpen){
        this.isOpen = true;
        }else{
          this.isOpen = false;
      }
    }
  }
}
</script>

<style lang="css" scoped>
.btn-style{
  border: none;
  padding: 2rem;
  background-color: #FFFFFF;
  width: 100%;
  display: flex;
  align-items: center;
}

.dropdown-headline{
  padding-left: 2rem;
}

.dropdown-style{
  background-color: #FFFFFF;
  margin-top: 1rem;
}

.dropdown-style>*{
  padding: 4rem;
}

.close{
  display: none;
}


@media screen and (max-width: 31.25em) {
  .btn-style{
    padding: 1rem;
    align-items: flex-end;
  }
  .dropdown-style>*{
    padding: 2rem;
  }
}
</style>