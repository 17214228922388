<template>
<div id="app">
  <Nav />
  <router-view :key="$route.fullPath"></router-view>
  <Footer/>
</div>
</template>

<script type="text/javascript">
import Nav from './components/element/Nav.vue';
import Footer from './components/element/Footer.vue';

export default {
  name: 'App',
  data() {
    return {
      metadatum: null,
      url: process.env.VUE_APP_STRAPI_API_URL
    };
  },
  components: {
    Nav,
    Footer
  }
}
</script>
