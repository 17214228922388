<template>
  <article class="ct cttb-style grey">
    <div class="headline-cttb">
      <h2>{{ body.headline }}</h2>
    </div>
    <div class="content-cttb white">
      <vue-markdown class="text-cttb markdown" :source="body.text"></vue-markdown>
      <div>
        <router-link v-if="body.btnStyle != null && body.btnStyle.page !== null && (body.btnStyle.link == '' || body.btnStyle.link == null)" class="btn-style" :class="{'btn-green': body.btnStyle.style == 'green', 'btn-grey': body.btnStyle.style == 'grey', 'btn-default': body.btnStyle.style == 'default'}" :to="{path: '/page/' + body.btnStyle.page.slug}" :key="body.btnStyle.id"><label>{{body.btnStyle.label}}</label></router-link>
        <a v-if="body.btnStyle != null && body.btnStyle.link !== null && body.btnStyle.link !== ''" class="btn-style" :class="{'btn-green': body.btnStyle.style == 'green', 'btn-grey': body.btnStyle.style == 'grey', 'btn-default': body.btnStyle.style == 'default'}" :href="body.btnStyle.link"><label>{{body.btnStyle.label}}</label></a>
      </div>
    </div>
  </article>
</template>

<script>
import VueMarkdown from 'vue-markdown'

export default {
  name: 'ContentTypeTextButton',
  data(){
    return {
          url: process.env.VUE_APP_STRAPI_API_URL
      };
  },
  props: {
    body: {
      type: Object
    },
    pos: {
      type: Number
    }
  },
  components: {
    VueMarkdown
  }
}
</script>

<style lang="css" scoped>
.cttb-style{
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  justify-content: center;
  align-items: center;
  height: max-content;
}

.headline-cttb{
  background-color: var(--fcmh-dark-grey);
  position: absolute;
  display: block;
  align-items: center;
  z-index: 1;
  padding: 6rem 10rem;
  width: 25%;
  height: max-content;
  right: 62%;
}

.headline-cttb>h2{
  font-family: Oswald;
  font-size: 4.5rem;
  font-style: normal;
  font-weight: 600;
  line-height: 5rem;
  letter-spacing: 0;
  text-align: left;
  color: var(--fcmh-white);
}

.content-cttb{
  position: inherit;
  width: 40%;
  text-align: center;
  padding: 2rem 10rem;
  padding-top: 5rem;
  display: block;
  height: max-content;
}

.content-cttb>.text-cttb{
  position: relative;
  text-align: left;
  left: 15%;
  margin-bottom: 2rem;
  width: 90%;
}

 /* Button Style */
.btn-style{
  font-size: 2.2rem;
}

@media screen and (max-width: 175rem){

  .headline-cttb {
    width: 20%;
    padding: 6rem 0 6rem 4rem;
  }

  .content-cttb {
    width: 40%;
    padding: 4rem 9rem;
  }

  .content-cttb>.text-cttb{
    margin: 1%;
  }
}

@media screen and (max-width: 104.188rem) {
  .headline-cttb{
    width: 23%;
    right: 70%;
    padding: 4rem 0 4rem 1rem
  }

  .headline-cttb>h2{
    font-size: 3.7rem;
  }

  .content-cttb{
    width: 65%;
  }

  .content-cttb>.text-cttb{
    margin-bottom: 0;
    left: 15%;
    width: 90%;
  }
}

@media screen and (max-width: 65.625em) and (max-height: 87.5em) {

  .cttb-style{
    width: 100%;
    height: max-content;
    justify-content: flex-start;
  }

  .headline-cttb{
    position: relative;
    width: 80%;
    right: 0;
    top: 5rem;
    padding: 3rem 2rem;
  }

  .headline-cttb>h2{
    font-size: 3.3rem;
  }

  .content-cttb{
    position: initial;
    width: 100%;
    padding: 4rem 6rem;
  }

  .content-cttb>.text-cttb{
    margin-top: 5rem;
    left: 0;
    width: 100%;
  }
}

@media screen and (max-width: 31.25em) {
  .headline-cttb{
    width: 100%;
    padding: 1.5rem 3rem;

  }

  .headline-cttb>h2{
    font-size: 3rem;
    word-break: break-word;
  }

  .content-cttb{
    padding: 6rem 2rem 4rem 2rem;
  }

  .content-cttb>.text-cttb{
    margin-top: 1rem;
  }

  /* Button Style */
  .btn-style{
    font-size: 1.9rem;
  }
}
</style>