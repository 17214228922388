<template lang="html">
  <article class="min-width ct row">
    <vue-markdown class="size markdown headline-green col" :source="body.inhalt"></vue-markdown>
    <div class="picture-box col">
      <img class="picture" :src="url + body.picture.url" :alt="body.picture.alternativeText">
      <vue-markdown class="pic-info markdown headline-grey" :source="body.picture_text"></vue-markdown>
    </div>
  </article>
</template>

<script>
import VueMarkdown from 'vue-markdown'

export default {
  name: 'ContentTypeTextBild',
  data(){
    return {
          url: process.env.VUE_APP_STRAPI_API_URL
      };
  },
  props: {
    body: {
      type: Object
    },
    pos: {
      type: Number
    }
  },
  components: {
    VueMarkdown
  }
}
</script>

<style lang="css" scoped>

.row{
  margin: 0;
}

@media screen and (max-width: 93.75em){
  .picture{
    width: 60%;
  }

  .size{
    min-width: 100%;
  }

  .pic-info{
    bottom: 0;
    position: inherit;
    left: 0;
    width: 80%;
    padding: 2.5rem 3.5rem;
  }
}
@media screen and (max-width: 62.5em){
  .picture{
    width: 100%;
  }

  .ct{
    padding: 2rem 1rem;
  }
}
@media screen and (max-width: 46.875em){
  .pic-info{
    width: 100%;
  }
}
</style>
