<template lang="html">
  <article :id="pos" class="ct-text-2">
    <div :class="{white: 'white' == body.background,grey: 'grey' == body.background,tabelleNormal: 'normal' == body.tableDesign,tabelleBlock: 'block' == body.tableDesign}">
      <vue-markdown :class="{'headline-green': 'white' == body.background,'headline-grey': 'grey' == body.background}" class="ct markdown mehrspaltig" :source="body.inhalt"></vue-markdown>
    </div>
  </article>
</template>

<script>
import VueMarkdown from 'vue-markdown'
import $ from 'jquery';

export default {
  name: 'ContentTypeText2',
  props: {
    body: {
      type: Object
    },
    pos: {
      type: Number
    }
  },
  mounted() {
      $('#' + this.pos + " table").wrap("<div class='col table-fcmh'></div>");

      $('#' + this.pos + " .table-fcmh").wrapAll("<div class='row'></div>");
  },
  components: {
    VueMarkdown
  }
}
</script>

<style lang="css" scoped>

</style>
