<template>
  <article class="ct ctkp-style white">
    <div class="component-ctkp">
      <div class="picture-ctkp">
          <img class="picture-style-ctkp picture" :src="url + body.bild.url" :alt="body.bild.alternativeText">
      </div>
      <div class=" content-ctkp grey markdown">
        <p class="position" :v-if="body.position">{{ body.position }}</p>

        <p v-if="body.kontaktdaten.name != null && body.kontaktdaten.name != ''" class="name">{{ body.kontaktdaten.name }}</p>

        <div v-if="(body.kontaktdaten.strasse_hsnr != null && body.kontaktdaten.strasse_hsnr != '') && (body.kontaktdaten.plz != null && body.kontaktdaten.plz != '') && (body.kontaktdaten.ort != null && body.kontaktdaten.ort != '')" class="adresse">
          <p>{{ body.kontaktdaten.strasse_hsnr }}</p>
          <p>{{ body.kontaktdaten.plz }} {{ body.kontaktdaten.ort }}</p>
        </div>

        <div v-if="(body.kontaktdaten.tel != null && body.kontaktdaten.tel != '' && body.kontaktdaten.tel) || (body.kontaktdaten.email != null && body.kontaktdaten.email != '' && body.kontaktdaten.email)" class="kontakt">
          <p v-if="body.kontaktdaten.tel != null && body.kontaktdaten.tel != '' && body.kontaktdaten.tel">Tel: {{ body.kontaktdaten.tel }}</p>
          <p v-if="body.kontaktdaten.email != null && body.kontaktdaten.email != '' && body.kontaktdaten.email">E-Mail: {{ body.kontaktdaten.email }}</p>
        </div>
      </div>
    </div>
  </article>
</template>

<script>

export default {
  name: 'ContentTypeKontaktperson',
  data(){
    return {
        url: process.env.VUE_APP_STRAPI_API_URL
    };
  },
  props: {
    body: {
      type: Object
    },
    pos: {
      type: Number
    }
  }
}
</script>

<style lang="css" scoped>
.ctkp-style{
  position: inherit;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: max-content;
  margin: 5rem 0;
}

.component-ctkp{
  background-color: var(--fcmh-dark-grey);
  display: flex;
  position: initial;
  width: max-content;
  height: max-content;
  padding: 3rem;
  justify-content: space-between;
  align-items: center;
}

.picture-ctkp{
  position: initial;
  max-width: 45%;
}

.picture-style-ctkp{
  display: block;
  max-width: 50rem;
  max-height: 50rem;
  overflow: hidden;
  border-radius: 50%;
}

.content-ctkp{
  position: initial;
  width: 50%;
  height: max-content;
  z-index: 900;
  padding: 3rem 5rem;
  transform: translate(8rem, 15%);
}

.position{
  font-family: Oswald;
  font-size: 3rem;
  font-weight: 500;
  line-height: 4rem;

  color: var(--fcmh-dark-grey);
}

.name{
  font-family: Oswald;
  font-size: 4rem;
  font-weight: 600;
  line-height: 4rem;

  color: var(--fcmh-green);

  margin: 0;
  padding: 3rem 0;
}

.adresse{
  font-family: Montserrat;
  font-size: 2.2rem;
  font-weight: 500;
  line-height: 3rem;
}

.kontakt{
  font-family: Montserrat;
  font-size: 2.2rem;
  font-weight: 600;
  line-height: 3rem;
}

.kontakt>p:first-child{
  padding-top: 2rem;
}

.kontakt>p, .kontakt>p, .position{
  margin: 0;
  padding: 0;
}

@media screen and (max-width: 175rem){
  .content-ctkp{
    width: 55%;
    padding: 4rem;
  }
}

@media screen and (max-width: 65.625em) and (max-height: 87.5em) {
  .ctkp-style{
    margin: 3rem 0 5rem 0;
  }

  .component-ctkp{
    width: 100%;
    flex-direction: column;
    padding: 0;
  }

  .content-ctkp{
    width: 90%;
    transform: translate(0, 10%);
    padding: 1rem 2rem;
  }

  .picture-ctkp{
    max-width: 60%;
    margin: 2rem 0;
    padding: 0;
  }
}

@media screen and (max-width: 31.25em) {
  .position{
    font-size: 2rem;
    line-height: 3rem;
  }

  .name{
    font-size: 2.5rem;
    line-height: 3rem;
  }

  .adresse, .kontakt{
    font-size: 1.5rem;
    line-height: 2rem;
  }
}
</style>
