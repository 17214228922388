<template lang="html">

<section id="headerBild">
    <div :style="{'backgroundImage': 'url(' + url + body.img.url +')'}" class="text-center header-wrapper">
        <h1>{{body.inhalt}}</h1>
    </div>
</section>

</template>

<script>

export default {
    name: 'ContentTypeHeaderMitBild',
    data() {
        return {
            url: process.env.VUE_APP_STRAPI_API_URL
        }
    },
    props: {
        body: {
            type: Object
        },
        pos: {
            type: Number
        }
    }
}

</script>

<style lang="css" scoped>

#headerBild {
    height: calc(70rem - 2vw);
}

#headerBild h1 {
    color: white;
    font-size: 7rem;
    background: rgba(121, 121, 121, .6);
    padding: 2rem 4rem;
    margin: 0;
}

.header-wrapper {
    height: calc(70rem - 2vw);
    height: -moz-calc(70rem - 4%);
    height: -webkit-calc(70rem - 4%);
    background-repeat: no-repeat;
    background-size: cover;
    background-position-y: 50%;
    background-position-x: center;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: 1rem solid var(--fcmh-olive);
}

@media screen and (max-width: 96.875em) {
    .header-wrapper,
    #headerBild {
        height: calc(60rem - 2vw);
    }
}

@media screen and (max-width: 58.125em) {
    #headerBild h1 {
        font-size: 5rem;
        padding: 1.5rem 3rem;
    }
}

@media screen and (max-width: 34.375em) and (max-height: 56.25em) {
    .header-wrapper,
    #headerBild {
        height: calc(50rem - 2vw);
    }
}

@media screen and (max-width: 34.375em) and (max-height: 45.625em) {
    .header-wrapper,
    #headerBild {
        height: calc(40rem - 2vw);
    }
}

@media screen and (max-width: 34.375em) and (max-height: 39.375em) {
    .header-wrapper,
    #headerBild {
        height: calc(30rem - 2vw);
    }
}

@media screen and (max-width: 26.875em) {
    #headerBild h1 {
        font-size: 4rem;
        padding: 1rem 2.5rem;
    }
}

@media screen and (max-width: 23.125em) {
    #headerBild h1 {
        font-size: 3rem;
        padding: .5rem 2rem;
    }
}

</style>
