import Vue from "vue";
import App from "./App.vue";
import VueApollo from "vue-apollo";
import apolloClient from "./vue-apollo";
import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import router from './router'
import VueMeta from 'vue-meta'
import VueSimpleMarkdown from 'vue-simple-markdown';
import {
  loadAnimation
} from 'lottie-web';
import {
  defineLordIconElement,
  LordIconElement
} from 'lord-icon-element';
import ClickReverseAnimation from './mixins/ClickReverseAnimation';

defineLordIconElement(loadAnimation);
Vue.use(VueMeta, {
  refreshOnceOnNavigation: true
})

LordIconElement.registerAnimation('clickReverseAnimation', ClickReverseAnimation);
Vue.config.productionTip = false;
Vue.use(VueApollo);
Vue.use(VueSimpleMarkdown);

const apolloProvider = new VueApollo({
  defaultClient: apolloClient
});

new Vue({
  apolloProvider,
  router,
  render: h => h(App)
}).$mount("#app");