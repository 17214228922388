<template lang="html">
  <article class="grey ct">
    <vue-markdown class="markdown headline-grey" :source="body.headline"></vue-markdown>
    
    <div class="spacer">
      <div v-for="value in body.dropdown" :key="value.key">
        <Dropdown :body="value"/>
        <vue-markdown v-if="value.text" class="markdown spacer-text" :source="value.text"></vue-markdown>
      </div>
    </div>
      
  </article>
</template>

<script>
import VueMarkdown from 'vue-markdown';
import Dropdown from '@/components/element/Dropdown';

export default {
  name: 'ContentTypeExtendedText',
  props: {
    body: {
      type: Object
    },
    pos: {
      type: Number
    }
  },
  components: {
    VueMarkdown,
    Dropdown
  }
}
</script>

<style lang="css" scoped>

.spacer{
  padding-top: 10rem;
}

.spacer-text{
  padding-top: 5rem;
  padding-bottom: 5rem;
}

@media screen and (max-width: 31.25em) {
  .spacer{
    padding-top: 4rem;
  }
 
  .spacer-text{
    padding-top: 1rem;
    padding-bottom: 2rem;
  }
}
</style>