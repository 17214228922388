<template>
  <article class="ct" :class="body.ctmbackground">
    <vue-markdown v-if="body.text" class="markdown headline-green" :source="body.text"></vue-markdown>
    <div v-if="this.fileExt == '.jpeg' || this.fileExt == '.jpg' || this.fileExt == '.png' || this.fileExt == '.svg' || this.fileExt == '.gif' || this.fileExt == '.tiff' || this.fileExt == '.ico' || this.fileExt == '.duv'">
      <img class="media" :src="url + body.media.url" :alt="body.media.alternativeText"/>
    </div>
    <div v-else-if="this.fileExt == '.mp4' || this.fileExt == '.mpeg' || this.fileExt == '.mpg' || this.fileExt == '.avi' || this.fileExt == '.wmv' || this.fileExt == '.mov' || this.fileExt == '.webm'">
      <video class="media" controls>
        <source :src="url + body.media.url" :alt="body.media.alternativeText">
        Your browser does not support the video tag.
      </video>
    </div>
    <vue-markdown class="markdown headline-green" :source="body.mediaInfo"></vue-markdown>
  </article>
</template>

<script>
import VueMarkdown from 'vue-markdown';

export default {
  name: 'ContentTypeMedia',
  data(){
    return {
      url: process.env.VUE_APP_STRAPI_API_URL,
      fileExt: this.body.media.ext.toLowerCase().trim()
    };
  },
  props: {
    body: {
      type: Object
    },
    pos: {
      type: Number
    }
  },
  components: {
    VueMarkdown
  }
}
</script>

<style scoped>
.media{
  width: 65%;
  margin: 0 auto;
}

@media screen and (max-width: 65.625em) and (max-height: 87.5em) {
  .media{
    width: 100%;
  }
}
</style>
