<template lang="html">

<section id="contact-form" class="ct">
    <h2 class="headline-grey">Kontaktformular</h2>
    <main>
        <div class="contact-form-inner row">
            <div v-if="body.socialmedia != null || body.kontaktdaten != null" class="col-md-auto col-lg-5">
                <div class="row">
                    <div v-if="body.kontaktdaten != null" class="col">
                        <div class="bg">
                            <div class="anschrift">
                                <p v-if="body.kontaktdaten.name">
                                    {{ body.kontaktdaten.name }}
                                </p>
                                <p v-if="body.kontaktdaten.strasse_hsnr">
                                    {{ body.kontaktdaten.strasse_hsnr }}
                                </p>
                                <p v-if="body.kontaktdaten.plz && body.kontaktdaten.ort">
                                    {{ body.kontaktdaten.plz }} {{ body.kontaktdaten.ort }}
                                </p>
                            </div>
                            <div class="kontaktdaten">
                                <p v-if="body.kontaktdaten.tel">
                                    Tel.: {{ body.kontaktdaten.tel }}
                                </p>
                                <p v-if="body.kontaktdaten.email">
                                    E-Mail.: {{ body.kontaktdaten.email }}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="socialmedia-row row">
                    <div v-if="body.socialmedia.length > 0" class="col">
                        <div class="bg">
                            <div class="socialmedia">
                                <p v-for="socialmedia in body.socialmedia" :key="socialmedia.id">
                                    <img v-if="socialmedia.plattform == 'facebook'" src="/assets/fcmh-facebook.svg" alt="Facebook logo" />
                                    <img v-if="socialmedia.plattform == 'instagram'" src="/assets/fcmh-instagram.svg" alt="Instagram logo" />
                                    <img v-if="socialmedia.plattform == 'twitter'" src="/assets/fcmh-twitter.svg" alt="Twitter logo" /> {{ socialmedia.name }}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-auto col-lg-auto col-xl-7">
                <div class="contact-form">
                    <form>
                        <label data-validate="Dieses Feld darf nicht leer sein!" class="input-label">
                            Name<span>*</span>
                            <br />
                            <input @focus="hideError" class="validate-input" name="name" type="text" id="name" />
                        </label>
                        <br />
                        <label data-validate="Dieses Feld darf nicht leer sein und muss das Format a@b.c haben!" class="input-label">
                            E-Mail<span>*</span>
                            <br />
                            <input @focus="hideError" class="validate-input" name="email" type="email" id="email" />
                        </label>
                        <br />
                        <label class="input-label">
                            Telefon
                            <br />
                            <input name="tel" type="text" id="tel" />
                        </label>
                        <br />
                        <label data-validate="Dieses Feld darf nicht leer sein!" class="input-label">
                            Nachricht<span>*</span>
                            <br />
                            <textarea @focus="hideError" class="validate-input" name="msg" id="msg" rows="8" cols="80"></textarea>
                        </label>
                        <br />
                        <label data-validate="Die Datenschutzbestimmungen müssen akzeptiert werden!" class="contact-info">
                            <input @focus="hideError" class="validate-input" type="checkbox" name="datenschutz" v-model="datenschutz" /> Hiermit bestätigen Sie unsere Datenschutzbestimmungen
                            <br>
                        </label>
                        <br />
                        <span>* Pflichtfelder</span>
                        <br />
                        <div v-if="mailSuccess" class="mail-success">
                            <p>Nachricht wurde erfolgreich verschickt!</p>
                        </div>
                        <div v-if="mailError" class="mail-error">
                            <p>
                                Es ist ein unerwarteter Fehler aufgetreten, bitte versuchen Sie es später erneut!
                            </p>
                        </div>
                        <div class="btn-wrapper text-center">
                            <a id="contactSubmitBtn" class="btn-grey" @click="send">
                                <label>Absenden</label>
                            </a>
                            <a id="contactloadingBtn" class="btn-grey">
                                <label>
                                    <div class="spinner-grow" role="status">
                                        <span class="sr-only">Lade...</span>
                                    </div>
                                </label>
                            </a>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </main>
</section>

</template>

<script>

import axios from "axios";
import $ from "jquery";
export default {
    name: "ContentTypeKontaktformular",
    props: {
        body: {
            type: Object
        },
        pos: {
            type: Number
        }
    },
    data() {
        return {
            datenschutz: false,
            mailError: false,
            mailSuccess: false
        };
    },
    methods: {
        send() {
                this.mailError = false;
                this.mailSuccess = false;
                var input = $(".validate-input");
                var check = true;
                for (var i = 0; i < input.length; i++) {
                    this.hideValidate(input[i]);
                    if (this.validate(input[i]) == false) {
                        var thisAlert = $(input[i]).parent();
                        $(thisAlert).addClass("alert-validate");
                        check = false;
                    }
                }
                if (check && this.datenschutz) {
                    var data = {
                        email: $("#email").val(),
                        name: $("#name").val(),
                        tel: $("#tel").val(),
                        msg: $("#msg").val(),
                        companyEmail: this.body.email
                    };
                    $("#contactSubmitBtn").css("display", "none");
                    $("#contactloadingBtn").css("display", "inline-block");
                    axios
                        .post("/api/mail", data)
                        .then(response => {
                            if (response.data.status === "success") {
                                this.mailSuccess = true;
                                this.mailError = false;
                                $("#contactSubmitBtn").css("display", "inline-block");
                                $("#contactloadingBtn").css("display", "none");
                            } else if (response.data.status === "failed") {
                                this.mailError = true;
                                this.mailSuccess = false;
                                $("#contactSubmitBtn").css("display", "inline-block");
                                $("#contactloadingBtn").css("display", "none");
                            }
                        })
                        .catch(() => {
                            this.mailError = true;
                            this.mailSuccess = false;
                            $("#contactSubmitBtn").css("display", "inline-block");
                            $("#contactloadingBtn").css("display", "none");
                        });
                }
            },
            validate: function(input) {
                var reMail = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

                if ($(input).attr('type') === 'email' || $(input).attr('name') === 'email') {
                    if ($(input).val().trim().match(reMail) === null) {
                        return false;
                    }
                } else {
                    if ($(input).val().trim() === '') {
                        return false;
                    }
                }

                if ($(input).attr('type') === 'textarea' && $(input).attr('name') === 'msg') {

                    if ($(input).val().trim() === '') {
                        return false;
                    }
                }

                if ($(input).attr('type') === 'text' && $(input).attr('name') === 'name') {
                    if ($(input).val().trim() === '') {

                        return false;
                    }
                }

                if ($(input).attr('type') === 'checkbox' && $(input).attr('name') === 'datenschutz') {
                    if ($(input).prop('checked') == false) {
                        return false;
                    }
                }

                return true;
            },
            hideValidate: function(e) {
                var input = e.target;
                var thisAlert = $(input).parent();

                $(thisAlert).removeClass("alert-validate");
            },
            hideError(e) {
                this.hideValidate(e);
            },
    }
};

</script>

<style lang="css" scoped>

/* === Input Validation === */

.mail-error,
.mail-success {
    font-size: 1.7rem;
}

.mail-success {
    color: var(--fcmh-green);
}

.mail-error {
    color: #af2525;
}

.alert {
    font-size: 1.6rem;
    text-align: left;
    padding: 1.3rem;
    display: inline-block;
    margin: 2rem auto;
}

.alert-validate::after {
    content: attr(data-validate);
    color: #af2525;
    font-size: 1.5rem;
    float: right;
}

.contact-info.alert-validate::after {
    content: attr(data-validate);
    color: #af2525;
    font-size: 1.5rem;
    float: left;
}

.contact-info.alert-validate {
    margin-bottom: 2rem;
}

#app #contactloadingBtn {
    display: none;
}


/* === === */

.bg {
    background: white;
    padding: 3vw 4vw;
    font-size: 2.5rem;
    width: max-content;
    min-width: 70rem;
    word-break: break-word;
}

.contact-form-inner {
    margin-top: 10rem;
}

.socialmedia-row {
    margin-top: 10rem;
}

#contact-form {
    background: var(--fcmh-light-grey);
}

.contact-form span {
    color: #af2525;
    font-weight: 400;
    font-size: 1.8rem;
}

.input-label {
    color: var(--fcmh-dark-grey);
    font-size: 3rem;
    font-weight: 600;
    width: 100%;
}

.input-label span {
    font-size: 2.8rem;
}

.contact-form input,
.contact-form textarea {
    font-size: 1.8rem;
    padding: 1.5rem 1rem;
}

.contact-form input[type="text"],
.contact-form input[type="email"],
.contact-form textarea {
    width: 100%;
    border: none;
}

.contact-form input:active,
.contact-form input:focus,
.contact-form textarea:active,
.contact-form textarea:focus {
    outline: none;
}

.contact-form .contact-info {
    font-size: 2rem;
    color: var(--fcmh-dark-grey);
    font-weight: 400;
}

.kontaktdaten p,
.anschrift p {
    margin: 0;
}

.kontaktdaten {
    margin-top: 5rem;
}

.socialmedia p:not(:last-child) {
    margin-bottom: 5rem;
}

.socialmedia img{
  margin-right: 2rem;
}

.btn-grey{
  font-size: 2rem;
}

.headline-grey{
  background-image: linear-gradient(var(--fcmh-grey), var(--fcmh-grey));
  color: var(--fcmh-dark-grey);
}

.btn-wrapper{
  margin: 1rem 0;
  margin-top: 2rem;
}

@media screen and (max-width: 125em) {
    .bg {
        min-width: auto;
        width: 100%;
        font-size: 2.3rem;
        padding: 2vw 3vw;
    }
    .contact-form {
        margin-left: 5vw;
    }
    .input-label {
        font-size: 2.3rem;
    }
}

@media screen and (max-width: 87.5em) {
    .bg {
        font-size: 2rem;
    }
    #contact-form {
        word-break: break-word;
    }
}

@media screen and (max-width: 75.625em) {
    .contact-form {
        margin-left: 0;
        width: 100%;
    }
    .socialmedia-row,
    .contact-form,
    .contact-form-inner {
        margin-top: 5vw;
    }
    .socialmedia p:not(:last-child) {
        margin-bottom: 5vw;
    }
    .kontaktdaten {
        margin-top: 5vw;
    }
    .col-lg-5 {
        flex: 0 0 100%;
        max-width: 100%;
    }
}

@media screen and (max-width: 37.5em) {
    .input-label {
        font-size: 2rem;
    }
    .bg {
        font-size: 1.8rem;
    }
    .socialmedia img {
        width: 7vw;
    }
    .contact-form input,
    .contact-form textarea {
        font-size: 1.6rem;
        padding: 1rem .5rem;
    }
}

@media screen and (max-width: 21.875em) {
    .alert-validate::after {
        font-size: 1.5rem;
    }
}

</style>
