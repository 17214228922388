<template>
  <article class="ct ctk-style grey">
    <div class="headline-ctk">
      <h2>Kontakt</h2>
    </div>
    <div class="content-ctk white">
      <div class="row">
        <div class="col text-ctk">
          <div class="adress-style markdown">
            <p>{{ body.kontaktdaten.name }}</p>
            <p>{{ body.kontaktdaten.strasse_hsnr }}</p>
            <p>{{ body.kontaktdaten.plz }} {{ body.kontaktdaten.ort }}</p>
          </div>
          <div class="adress-style markdown">
            <p>Tel: {{ body.kontaktdaten.tel }}</p>
            <p>E-Mail: {{ body.kontaktdaten.email }}</p>
          </div>
        </div>
        <div class="col text-ctk markdown" v-if="body.socialmedia">
          <p class="socialmedia-style" v-for="socialmedia in body.socialmedia" :key="socialmedia.id">
            <img v-if="socialmedia.plattform == 'facebook'" src="/assets/fcmh-facebook.svg" alt="Facebook logo" />
            <img v-if="socialmedia.plattform == 'instagram'" src="/assets/fcmh-instagram.svg" alt="Instagram logo" />
            <img v-if="socialmedia.plattform == 'twitter'" src="/assets/fcmh-twitter.svg" alt="Twitter logo" />
            {{ socialmedia.name }}
          </p>
        </div>
      </div>
    </div>
  </article>
</template>

<script>

export default {
  name: 'ContentTypeKontakt',
  data(){
    return {
          url: process.env.VUE_APP_STRAPI_API_URL
      };
  },
  props: {
    body: {
      type: Object
    },
    pos: {
      type: Number
    }
  }
}
</script>

<style lang="css" scoped>
.ctk-style{
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  justify-content: center;
  align-items: center;
  height: max-content;
}

.headline-ctk{
  background-color: var(--fcmh-dark-grey);
  position: absolute;
  display: block;
  align-items: center;
  z-index: 1;
  padding: 6rem 10rem;
  width: 25%;
  height: max-content;
  right: 62%;
}

.headline-ctk>h2{
  font-family: Oswald;
  font-size: 4.5rem;
  font-style: normal;
  font-weight: 600;
  line-height: 5rem;
  letter-spacing: 0;
  text-align: left;
  color: var(--fcmh-white);
}

.content-ctk{
  position: inherit;
  width: 40%;
  text-align: center;
  padding: 2rem 10rem;
  padding-top: 5rem;
  display: block;
  height: max-content;
}

.content-ctk>div{
  align-items: center;
}

.text-ctk{
  position: relative;
  text-align: left;
  left: 15%;
  margin-bottom: 2rem;
  min-width: max-content;
  height: min-content;
}

.text-ctk>.socialmedia-style{
  font-weight: 600;
  font-size: 2.5rem;
}

.text-ctk>.socialmedia-style>img{
  padding-right: 2rem;
  max-width: 30%;
}

.text-ctk>.adress-style>p{
  margin: 0;
}

.text-ctk>.adress-style:first-child{
  margin-bottom: 2rem;
}

@media screen and (max-width: 175rem){

  .headline-ctk {
    width: 20%;
    padding: 6rem 0 6rem 4rem;
  }

  .content-ctk {
    width: 40%;
    padding: 4rem 9rem;
  }

  .text-ctk{
    margin: 1%;
  }
}

@media screen and (max-width: 104.188rem) {
  .headline-ctk{
    width: 23%;
    right: 70%;
    padding: 4rem 0 4rem 1rem
  }

  .headline-ctk>h2{
    font-size: 3.7rem;
  }

  .content-ctk{
    width: 65%;
  }

  .text-ctk{
    margin-bottom: 0;
    left: 15%;
    width: 90%;
  }
}

@media screen and (max-width: 65.625em) and (max-height: 87.5em) {

  .ctk-style{
    width: 100%;
    height: max-content;
    justify-content: flex-start;
  }

  .headline-ctk{
    position: relative;
    width: 80%;
    right: 0;
    top: 5rem;
    padding: 3rem 2rem;
  }

  .headline-ctk>h2{
    font-size: 3.3rem;
  }

  .content-ctk{
    position: initial;
    width: 100%;
    padding: 4rem 6rem;
  }

  .text-ctk{
    margin-top: 5rem;
    left: 0;
    width: 100%;
  }
}

@media screen and (max-width: 43.75em){
  .text-ctk{
    min-width: 100%;
  }
}

@media screen and (max-width: 31.25em) {
  .headline-ctk{
    width: 100%;
    padding: 1.5rem 3rem;

  }

  .headline-ctk>h2{
    font-size: 3rem;
    word-break: break-word;
  }

  .content-ctk{
    padding: 6rem 2rem 4rem 2rem;
  }

  .text-ctk{
    margin-top: 1rem;
    min-width: 100%;
  }

  .text-ctk>.socialmedia-style{
    font-size: 1.8rem;
  }
}
</style>
