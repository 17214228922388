<template lang="html">
  <nav id="ct-nav" class="padding-lr">
    <div id="nav-desktop">
      <div class="logo-wrapper">
        <a href="/"><img v-if="mainNav.logo" class="logo" :src="url + mainNav.logo.url" :alt="mainNav.logo.alternativeText" /></a>
      </div>
      <div class="nav-elem-container">
        <div class="nav-elem" v-for="body in mainNav.body" :key="body.id">
          <div class="ComponentElementDropdownLink" v-if="body.__typename === 'ComponentElementDropdownLink'">
            <ul>
              <li class="dropdown">
                <button class="dropdown-root">
                  {{ body.label }}
                  <i class="fa fa-caret-down"></i>
                </button>
                <ul class="dropdown-content">
                  <div v-for="abteilung in body.abteilungens" :key="abteilung.id">
                    <li class="dropdown-nested">
                      <a class="dropbtn-nested">{{ abteilung.name }}</a>
                      <ul class="dropdown-content-nested">
                        <li v-for="page in abteilung.pages" :key="page.id">
                          <router-link class="dropdown-item" :to="{ path: '/page/' + page.slug }">{{ page.title }}</router-link>
                        </li>
                      </ul>
                    </li>
                  </div>
                </ul>
              </li>
            </ul>
          </div>
          <div v-if="body.__typename === 'ComponentElementNavLink'">
            <ul class="navbar-nav ml-auto mt-2 mt-lg-0">
              <li class="nav-item active">
                <router-link v-bind:class="{'btn-green': body.style == 'green', 'btn-grey': body.style == 'grey'}" v-if="body.page !== null && (body.link == '' || body.link == null)" class="nav-link btn-default" :to="{ path: '/page/' + body.page.slug }" :key="body.id">
                  <label>
                    {{ body.label }}
                  </label>
                </router-link>
                <a v-if="body.link !== null && body.link !== ''" class="nav-link btn-default" v-bind:class="{'btn-green': body.style == 'green', 'btn-grey': body.style == 'grey'}" :href="body.link">
                  <label>
                    {{ body.label }}
                  </label>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>

    <div id="nav-mobile" :class="{ 'nav-open': navOpen }">
      <a class="navbar-logo" href="/">
        <img :src="this.url + mainNav.logo.url" height="50" :alt="mainNav.logo.alternativeText" />
      </a>
      <div id="menuToggle">
        <input @click="navTrigger" type="checkbox" />
        <span></span>
        <span></span>
        <span></span>
      </div>
      <div id="mySidenav" class="sidenav">
        <div class="Sidenav-bg">
          <div class="Sidenav-bg-inner">
            <div class="navbar-collapse" v-for="body in mainNav.body" :key="body.id">
              <div class="ComponentElementDropdownLinkMobile" v-if="body.__typename === 'ComponentElementDropdownLink'">
                <ul>
                  <li class="dropdown-mobile">
                    <button class="dropdown-root-mobile">
                      {{ body.label }}
                      <i class="fa fa-caret-down"></i>
                    </button>
                    <ul class="dropdown-content-mobile">
                      <div v-for="abteilung in body.abteilungens" :key="abteilung.id">
                        <li class="dropdown-nested-mobile">
                          <button class="dropbtn-nested-mobile">
                            {{ abteilung.name }}
                            <i class="fa fa-caret-down"></i>
                          </button>
                          <ul class="dropdown-content-nested-mobile">
                            <li v-for="page in abteilung.pages" :key="page.id">
                              <router-link v-on:click.native="navTrigger" class="dropdown-item-mobile" :to="{ path: '/page/' + page.slug }">{{ page.title }}</router-link>
                            </li>
                          </ul>
                        </li>
                      </div>
                    </ul>
                  </li>
                </ul>
              </div>
              <div v-if="body.__typename === 'ComponentElementNavLink'">
                <ul>
                  <li class="nav-item">
                    <router-link v-on:click.native="navTrigger" v-bind:class="{'btn-green': body.style == 'green', 'btn-grey': body.style == 'grey'}" v-if="body.page !== null && (body.link == '' || body.link == null)" class="nav-link" :to="{ path: '/page/' + body.page.slug }" :key="body.id">
                      <label>
                        {{ body.label }}
                      </label>
                    </router-link>
                    <a v-on:click.native="navTrigger" v-if="body.link !== null && body.link !== ''" class="nav-link" v-bind:class="{'btn-green': body.style == 'green', 'btn-grey': body.style == 'grey'}" :href="body.link">
                      <label>
                        {{ body.label }}
                      </label>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
import gql from "graphql-tag";
import $ from "jquery";
export default {
  name: "Nav",
  data() {
    return {
      mainNav: {},
      url: process.env.VUE_APP_STRAPI_API_URL,
      navOpen: false
    };
  },
  methods: {
    navTrigger: function() {
      if (this.navOpen) {
        this.navOpen = false;
        $("#menuToggle input").prop("checked", false);
        document.getElementById("mySidenav").style.left = "-100%";
      } else {
        document.getElementById("mySidenav").style.left = "0";
        $("#menuToggle input").prop("checked", true);
        this.navOpen = true;
      }
    },
    changeNavCss: function() {
      if (window.scrollY > 100) {
        $("#ct-nav").addClass("nav-scrolled");
      } else {
        $("#ct-nav").removeClass("nav-scrolled");
      }
    }
  },
  mounted() {
    window.addEventListener("scroll", this.changeNavCss);
  },
  destroy() {
    window.removeEventListener("scroll", this.changeNavCss);
  },
  apollo: {
    mainNav: {
      query: gql`
        query {
          mainNav {
            logo {
              alternativeText
              url
            }
            body {
              __typename
              ... on ComponentElementDropdownLink {
                id
                label
                abteilungens {
                  id
                  name
                  pages {
                    id
                    title
                    slug
                  }
                }
              }
              __typename
              ... on ComponentElementNavLink {
                id
                label
                link
                style
                page {
                  id
                  slug
                }
              }
            }
          }
        }
      `
    }
  }
};
</script>

<style lang="css" scoped>
nav {
  background: var(--fcmh-dark-green);
  width: 100%;
  position: fixed;
  z-index: 999;
  min-height: 11rem;
  max-height: 11rem;
  display: flex;
  justify-content: center;
}

#nav-desktop{
  width: 100%;
}

#nav-mobile {
  display: none;
}

.logo {
  width: 13rem;
  position: absolute;
  padding-top: 3rem;
}

.nav-elem {
  padding: 3rem 2rem;
  display: flex;
  align-items: center;
}

.nav-elem a,
.dropdown-root {
  color: white;
  font-size: 2.5rem;
  font-weight: 500;
  border: none;
  outline: none;
  background-color: inherit;
}

.nav-elem-container {
  display: flex;
  justify-content: space-between;
  margin-left: 25rem;
  height: 100%;
}

.dropdown .dropbtn,
.dropdown-nested .dropbtn-nested {
  font-size: 16px;
  border: none;
  outline: none;
  color: white;
  padding: 14px 16px;
  background-color: var(--fcmh-dark-green);
  font-family: inherit;
  margin: 0;
  min-width: 25rem;
  max-width: 25rem;
  text-align: left;
  word-break: break-word;
  border-left: 0.3rem solid var(--fcmh-dark-green);
}

.dropdown-content {
  position: absolute;
}

.dropdown-content,
.dropdown-content-nested {
  display: none;
  background-color: var(--fcmh-dark-green);
  min-width: max-content;
  z-index: 1;
  top: 10.7rem;
}

.dropdown-content{
  top: 11rem
}

.dropdown-nested {
  position: relative;
  display: flex;
  width: max-content;
}

.dropdown-content a {
  font-size: 16px;
  border: none;
  outline: none;
  color: white;
  padding: 14px 16px;
  background-color: var(--fcmh-dark-green);
  font-family: inherit;
  margin: 0;
  min-width: 25rem;
  max-width: 25rem;
  text-align: left;
  word-break: break-word;
  border-left: 0.3rem solid var(--fcmh-dark-green);
}

.dropdown-content a:hover,
.dropdown-nested .dropbtn-nested:hover {
  background-color: var(--fcmh-olive);
  border-left: 0.3rem solid var(--fcmh-green);
  text-decoration: none;
}

.nav-elem:hover .dropdown .dropdown-content,
.dropdown-nested:hover .dropdown-content-nested {
  display: flex;
  flex-direction: column;
}

.dropdown {
  position: inherit;
}

.ComponentElementDropdownLink > ul li ul li ul {
  position: absolute;
  left: 100%;
  top: 0;
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

label {
  margin: 0;
}

@media screen and (max-width: 112.5em) {
  .nav-elem-container {
    margin-left: 10vw;
  }

  .nav-elem a,
  .dropdown-root {
    color: white;
    font-size: 2.2rem;
  }

  nav .dropdown-item {
    font-size: 1.6rem;
  }
}

@media screen and (max-width: 91.5625em) {
  .nav-elem {
    padding: 1rem 0rem;
  }

  .logo {
    width: 10rem;
    padding-top: 2rem;
  }

  .dropdown-content-nested {
    top: 8.7rem;
  }

  .dropdown-content{
    top: 7rem;
  }
}

@media screen and (max-width: 84.0625em) {
  nav {
    padding: 0rem 5rem;
  }

  nav {
    min-height: 7rem;
    max-height: 7rem;
  }
}
@media screen and (max-width: 75em) {
  .nav-elem-container {
    margin-left: 8vw;
  }

  .logo {
    width: 8rem;
  }

  .dropdown-content,
  .dropdown-content-nested {
    top: 6.3rem;
  }
}

@media screen and (max-width: 66.25em) {
  #nav-desktop {
    display: none;
  }

  #nav-mobile {
    display: flex;
    width: 100%;
    height: 7rem;
    justify-content: space-between;
  }

  .btn-green, .btn-grey{
    line-height: normal !important;
  }

  .navbar-logo img {
    width: 7rem;
    position: absolute;
    padding-top: 2rem;
    height: auto;
    transition: all 0.5s ease;
    z-index: 999;
  }

  .navbar-expand-lg .dropdown-menu {
    position: unset !important;
  }

  .nav-scrolled {
    transition: unset;
  }

  .navbar-inner {
    padding: 0;
  }

  .nav-scrolled .navbar-inner {
    transition: unset;
    padding: inherit;
    background-image: none;
    background-repeat: no-repeat;
    background-size: contain;
  }

  .nav-scrolled .navbar-brand img {
    transition: unset;
    padding: inherit;
  }

  .nav-scrolled .dropdown-menu {
    background-color: inherit;
    border-top: inherit;
  }

  .navbar-collapse {
    flex-grow: 0;
  }
  #mySidenav {
    width: 100%;
    left: -100%;
    text-align: left;
  }
  .navbar {
    padding: 0;
  }
  .sidenav {
    height: 100%;
    width: 0;
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    overflow-x: hidden;
    transition: 0.5s;
    margin-top: 7rem;
  }
  .sidenav a {
    text-decoration: none;
    font-size: 3rem;
    font-weight: 500;
    color: white;
    display: block;
    transition: 0.3s;
  }
  .sidenav .closebtn {
    position: absolute;
    top: 0;
    right: 25px;
    font-size: 36px;
    margin-left: 50px;
  }
  #menuToggle {
    display: flex;
    justify-content: center;
    flex-direction: column;
    position: relative;
    z-index: 1;
    -webkit-user-select: none;
    user-select: none;
    margin-right: 1rem;
  }
  #menuToggle input {
    display: block;
    width: 40px;
    height: 32px;
    position: absolute;
    cursor: pointer;
    opacity: 0;
    z-index: 2;
    -webkit-touch-callout: none;
  }
  #menuToggle span {
    display: flex;
    width: 33px;
    height: 4px;
    margin-bottom: 5px;
    position: relative;
    background: white;
    border-radius: 3px;
    z-index: 1;
    transform-origin: 4px 0px;
    transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1),
      background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1), opacity 0.55s ease;
  }
  #menuToggle span:first-child {
    transform-origin: 0% 0%;
  }
  #menuToggle span:nth-last-child(2) {
    transform-origin: 0% 100%;
  }
  #menuToggle input:checked ~ span {
    opacity: 1;
    transform: rotate(-45deg) translate(0, -3px);
    background: white;
  }
  #menuToggle input:checked ~ span:nth-last-child(3) {
    opacity: 1;
    transform: rotate(45deg) translate(0, -1px);
  }
  #menuToggle input:checked ~ span:nth-last-child(2) {
    transform: rotate(0deg) scale(0.2, 0.2);
    opacity: 0;
  }
  .Sidenav-bg-inner {
    height: inherit;
    height: 100%;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding: 10% 5%;
  }
  .Sidenav-bg {
    min-height: calc(100% - 7rem);
    background-color: var(--fcmh-dark-green);
    background-repeat: no-repeat;
    background-size: contain;
    display: flex;
    flex-direction: column;
  }
  .dropdown-menu a {
    font-size: 2.4rem;
    font-weight: 400;
  }
  .dropdown-toggle::after {
    font-size: 2.2rem;
  }
  .navbar-graphic {
    width: 100%;
    padding: 10vw;
  }

  .dropdown-mobile {
    padding: 2vw 0;
    border-bottom: 0.3rem solid var(--fcmh-olive);
  }

  /* Dropdown Click */

  .dropdown-content-mobile,
  .dropdown-content-nested-mobile {
    display: none;
  }

  .dropdown-mobile:hover .dropdown-content-mobile {
    display: block;
  }

  .dropdown-nested-mobile:hover .dropdown-content-nested-mobile {
    display: block;
  }

  /* === */

  .dropbtn-nested-mobile {
    padding: 0;
    outline: 0;
    border: 0;
    color: white;
    font-size: 3rem;
    background-color: inherit;
  }

  #nav-mobile .dropbtn-nested-mobile,
  #nav-mobile .dropdown-root-mobile {
    color: white;
    font-weight: 500;
  }

  #nav-mobile .dropdown-item-mobile {
    font-weight: 300;
  }

  .dropdown-content-nested-mobile {
    padding-left: 5vw;
    color: white;
  }

  .dropdown-root-mobile {
    padding: 0;
    padding-left: 5vw;
    outline: 0;
    border: 0;
    color: white;
    font-size: 3rem;
    background-color: inherit;
  }

  .dropdown-nested-mobile {
    padding-left: 10vw;
  }

  #nav-mobile .nav-link {
    padding-left: 0;
    transform-origin: top;
  }

  #nav-mobile .nav-item {
    padding: 2vw 0;
    padding-left: 5vw;
    border-bottom: 0.3rem solid var(--fcmh-olive);
  }
}

@media screen and (max-width: 35em) {
  nav {
    padding: 0rem 2rem;
  }

  #menuToggle {
    margin: 0;
  }

  #nav-mobile .dropdown-root-mobile,
  #nav-mobile .dropbtn-nested-mobile,
  #nav-mobile .nav-link {
    font-size: 2.2rem;
  }

  .dropbtn-nested-mobile,
  .dropdown-item-mobile {
    padding: 0.5rem 0;
  }

  #nav-mobile .dropdown-item-mobile {
    font-size: 2rem;
  }
}

@media screen and (max-width: 28.125em) {
  .Sidenav-bg-inner {
    padding: 10% 2.5%;
    padding-top: 12%;
  }

  .dropdown-nested-mobile {
    padding-left: 8vw;
  }

  .dropdown-content-nested-mobile {
    padding-left: 4vw;
  }
}

@media screen and (max-width: 25em) {
  .dropdown-root-mobile,
  #nav-mobile .nav-item {
    padding-left: 2vw;
  }

  .dropdown-nested-mobile {
    padding-left: 6vw;
  }

  .ComponentElementDropdownLinkMobile {
    word-break: break-all;
  }
}

@media screen and (max-width: 20.625em) {
  #nav-mobile .dropdown-root-mobile,
  #nav-mobile .dropbtn-nested-mobile,
  #nav-mobile .nav-link {
    font-size: 1.9rem;
  }

  #nav-mobile .dropdown-item-mobile {
    font-size: 1.6rem;
  }
}
</style>
