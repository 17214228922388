<template>
<section id="bildergalerie" class="ct" :class="body.ctbbackground">
    <vue-markdown class="markdown headline-green" :source="body.ctbtext"></vue-markdown>

    <div class="carousel-position">
      <carousel @page-change="PageChangeDragSlide" ref="fcmhCarousel" :paginationEnabled="false" :perPage="1" :scrollPerPage="false">
          <slide v-for="bild in body.bilder" :key="bild.id">
              <img :src="url + bild.url" :alt="bild.alternativeText" class="media" />
          </slide>
      </carousel>

      <div class="carousel-navigation">
          <button class="fas fa-angle-left btn-left" type="button" @click="SlideCarousel('prev')" name="button"></button>
          <button class="fas fa-angle-right btn-right" type="button" @click="SlideCarousel('next')" name="button"></button>
      </div>
    </div>

    <div class="pagination-dots">
        <button type="button" name="button" v-for="i in this.sliderCount" :key="i.id" class="fa-circle slider-custom-pagination" :class="{fas : i == activePage , far : i != activePage}" @click="PaginationCarousel(i)"></button>
    </div>
</section>

</template>

<script>

import VueMarkdown from 'vue-markdown';
import {
    Carousel, Slide
}
from 'vue-carousel';


export default {
    name: 'ContentTypeTextBild',
    data() {
        return {
            url: process.env.VUE_APP_STRAPI_API_URL,
            sliderCount: 0,
            activePage: 1
        };
    },
    props: {
        body: {
            type: Object
        },
        pos: {
            type: Number
        }
    },
    components: {
        VueMarkdown,
        Carousel,
        Slide
    },
    methods: {
        SlideCarousel(value) {
                if (typeof this.$refs.fcmhCarousel !== 'undefined') {
                    const carousel = this.$refs.fcmhCarousel;
                    const currentPage = carousel.currentPage;
                    const pageCount = carousel.pageCount;
                    if (value == 'prev') {
                        currentPage != 0 ? carousel.goToPage(currentPage - 1) : carousel.goToPage(pageCount - 1);
                        this.activePage = this.activePage - 1;
                    } else {
                        currentPage < pageCount - 1 ? carousel.goToPage(currentPage + 1) : carousel.goToPage(0);
                        this.activePage = this.activePage + 1;
                    }
                    if (currentPage == pageCount - 1) {
                        this.activePage = 1;
                    }
                }
            },
            PaginationCarousel(page) {
                if (typeof this.$refs.fcmhCarousel !== 'undefined') {
                    const carousel = this.$refs.fcmhCarousel;
                    this.activePage = page;
                    carousel.goToPage(page - 1);
                }
            },
            PageChangeDragSlide(page) {
                this.activePage = page + 1;
            }
    },
    mounted() {
        if (this.$refs.fcmhCarousel !== 'undefined') {
            this.sliderCount = this.$refs.fcmhCarousel.pageCount;
        }
    }
}

</script>

<style scoped>

.media {
    width: 100%;
    max-height: 50rem;
    min-height: 50rem;
    object-fit: scale-down;
}

.carousel-position{
  margin: 5rem 0;
}

.carousel-navigation {
    position: absolute;
    width: 100%;
    top: 46%;
    left: 0%;
}

#bildergalerie {
    position: relative;
    height: 100%;
    width: 100%;
}

.btn-left {
    position: absolute;
    z-index: 99;
    left: 7%;
}

.btn-right {
    position: absolute;
    z-index: 99;
    right: 7%;
}

.carousel-navigation button {
    background-color: var(--fcmh-green);
    color: white;
    border: none;
    font-size: 4rem;
    padding: 1rem 2rem;
}

.pagination-dots {
    display: flex;
    justify-content: center;
}

.slider-custom-pagination {
    border: none;
    background: none;
    font-size: 1.5rem;
}

.slider-custom-pagination {
    color: var(--fcmh-green);
}

.carousel-navigation:focus,
.slider-custom-pagination:focus {
    outline: none;
}

@media screen and (max-width: 34.375em) {
    .media {
        max-height: 25rem;
        min-height: 25rem;
    }
    .carousel-navigation button {
        font-size: 3rem;
    }
    .btn-left {
        left: 5%;
    }
    .btn-right {
        right: 5%;
    }
}

</style>
