<template>

<main class="main">
    <div v-for="(body, index) in homepage.body" :key="body.id">
        <div v-if="body.__typename === 'ComponentContentTypeContentTypeText1'">
            <ContentTypeText1 :body="body" :pos="index" />
        </div>
        <div v-if="body.__typename === 'ComponentContentTypeContentTypeText2'">
            <ContentTypeText2 :body="body" :pos="index" />
        </div>
        <div v-if="body.__typename === 'ComponentContentTypeContentTypeTextBild'">
            <ContentTypeTextBild :body="body" :pos="index" />
        </div>
        <div v-if="body.__typename === 'ComponentContentTypeContentTypeExtendedText'">
            <ContentTypeExtendedText :body="body" :pos="index" />
        </div>
        <div v-if="body.__typename === 'ComponentContentTypeContentTypeTextButton'">
            <ContentTypeTextButton :body="body" :pos="index" />
        </div>
        <div v-if="body.__typename === 'ComponentContentTypeContentTypeKontakt'">
            <ContentTypeKontakt :body="body" :pos="index" />
        </div>
        <div v-if="body.__typename === 'ComponentContentTypeContentTypeKontaktformular'">
            <ContentTypeKontaktformular :body="body" :pos="index" />
        </div>
        <div v-if="body.__typename === 'ComponentContentTypeContentTypeLandingsection1'">
          <ContentTypeLandingsection1 :body="body" :pos="index" />
        </div>
        <div v-if="body.__typename === 'ComponentContentTypeContentTypeHeaderMitBild'">
          <ContentTypeHeaderMitBild :body="body" :pos="index" />
        </div>
        <div v-if="body.__typename === 'ComponentContentTypeContentTypeKontaktperson'">
          <ContentTypeKontaktperson :body="body" :pos="index" />
        </div>
        <div v-if="body.__typename === 'ComponentContentTypeContentTypeDownload'">
          <ContentTypeDownload :body="body" :pos="index" />
        </div>
        <div v-if="body.__typename === 'ComponentContentTypeContentTypeHeaderOhneBild'">
          <ContentTypeHeaderOhneBild :body="body" :pos="index" />
        </div>
        <div v-if="body.__typename === 'ComponentContentTypeContentTypeMedia'">
          <ContentTypeMedia :body="body" :pos="index" />
        </div>
        <div v-if="body.__typename === 'ComponentContentTypeContentTypeBildergalerie'">
          <ContentTypeBildergalerie :body="body" :pos="index" />
        </div>
    </div>
</main>

</template>

<script>
import gql from "graphql-tag";

import ContentTypeText1 from "@/components/contentType/ContentTypeText1";
import ContentTypeText2 from "@/components/contentType/ContentTypeText2";
import ContentTypeTextBild from "@/components/contentType/ContentTypeTextBild";
import ContentTypeExtendedText from "@/components/contentType/ContentTypeExtendedText";
import ContentTypeTextButton from "@/components/contentType/ContentTypeTextButton";
import ContentTypeKontakt from "@/components/contentType/ContentTypeKontakt";
import ContentTypeKontaktformular from "@/components/contentType/ContentTypeKontaktformular";
import ContentTypeLandingsection1 from "@/components/contentType/ContentTypeLandingsection1";
import ContentTypeHeaderMitBild from '@/components/contentType/ContentTypeHeaderMitBild';
import ContentTypeHeaderOhneBild from '@/components/contentType/ContentTypeHeaderOhneBild';
import ContentTypeKontaktperson from '@/components/contentType/ContentTypeKontaktperson';
import ContentTypeDownload from '@/components/contentType/ContentTypeDownload';
import ContentTypeMedia from '@/components/contentType/ContentTypeMedia';
import ContentTypeBildergalerie from '@/components/contentType/ContentTypeBildergalerie';

export default {
  data() {
    return {
      homepage: []
    };
  },
  metaInfo() {
    if (typeof this.homepage.title != "undefined") {
      return {
        title: `${this.homepage.title}`
      };
    } else {
      return "FC Maxhütte-Haidhof";
    }
  },
  components: {
    ContentTypeText1,
    ContentTypeText2,
    ContentTypeTextBild,
    ContentTypeExtendedText,
    ContentTypeTextButton,
    ContentTypeKontaktformular,
    ContentTypeKontakt,
    ContentTypeLandingsection1,
    ContentTypeHeaderMitBild,
    ContentTypeKontaktperson,
    ContentTypeDownload,
    ContentTypeHeaderOhneBild,
    ContentTypeMedia,
    ContentTypeBildergalerie
  },
  apollo: {
    homepage: {
      query: gql `
        query {
        homepage{
          id
          title
          body {
            __typename
            ... on ComponentContentTypeContentTypeText1 {
              id
              inhalt: text
              cttext1background
              tabelleDesign
            }
            __typename
            ... on ComponentContentTypeContentTypeText2 {
              id
              inhalt: text
              background
              tableDesign: tabelleDesign
            }
            __typename
            ... on ComponentContentTypeContentTypeTextBild {
              id
              inhalt: text
              picture_text
              picture {
                id
                alternativeText
                url
                caption
              }
            }
            __typename
            ... on ComponentContentTypeContentTypeExtendedText {
              id
              headline
              dropdown {
                id
                headline
                dropdowntext
                text
              }
            }
            __typename
            ... on ComponentContentTypeContentTypeTextButton {
              id
              headline
              text
              btnStyle {
                id
                label
                page {
                  id
                  slug
                }
                link
                style
              }
            }
            __typename
            ... on ComponentContentTypeContentTypeKontaktformular {
              id
              email
              socialmedia {
                id
                plattform
                name
              }
              kontaktdaten {
                id
                strasse_hsnr
                plz
                ort
                name
                tel
                email
              }
            }
            __typename
            ... on ComponentContentTypeContentTypeKontakt {
              id
              socialmedia {
                id
                plattform
                name
              }
              kontaktdaten {
                id
                strasse_hsnr
                plz
                ort
                name
                tel
                email
              }
            }
            __typename
            ... on ComponentContentTypeContentTypeKontaktperson {
              id
              position
              bild {
                id
                name
                alternativeText
                url
                caption
              }
              kontaktdaten {
                id
                strasse_hsnr
                plz
                ort
                name
                tel
                email
              }
            }
            __typename
            ... on ComponentContentTypeContentTypeLandingsection1 {
              img {
                id
                url
                alternativeText
              }
              DepartmentHighlight {
                id
                abteilungen {
                  id
                  name
                }
                unterseiten {
                  slug
                }
              }
            }
            __typename
            ... on ComponentContentTypeContentTypeHeaderMitBild {
              id
              inhalt
              img {
                alternativeText
                url
              }
            }
            __typename
            ... on ComponentContentTypeContentTypeHeaderOhneBild {
              id
              inhalt
            }
            __typename
            ... on ComponentContentTypeContentTypeDownload {
              id
              headline
              document {
                id
                name
                datei {
                  id
                  url
                  name
                }
              }
            }
            __typename
            ... on ComponentContentTypeContentTypeMedia {
              id
              text
              mediaInfo
              ctmbackground
              media {
                id
                alternativeText
                ext
                url
              }
            }
            __typename
            ... on ComponentContentTypeContentTypeBildergalerie {
              id
              ctbtext
              ctbbackground
              bilder {
                id
                alternativeText
                url
              }
            }
          }
        }
      }
      `
    }
  }
};

</script>
